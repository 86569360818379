.App {
  text-align: center;
}

.xcene-logo {
  height: 14vmin;
  pointer-events: none;
}

.App-header {
  padding-top: 10vh;
  background-color:  #000; /*#181818;*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.cover-phone {
  width: 100vw;
}

h1 {
  color: #fff;
  font-family: "arista_regular";
  font-size: 32px;
  margin: 100px;
}

